import React, { useState } from 'react';
import "./Css/Banner.css";
import BannerImg from '../images/hero-image.png'
import CountUp from 'react-countup';
const Banner = () => {
    const [search, setSearch] = useState("")
    return (
        <div className="bg-dark">
            <div className='container '>
                <div className='row py-5'>
                    <div className='col-lg-5  text-light'>
                        <h1 className='title my-5 '>Discover <br /> Most Suitable <br />Property</h1>
                        <p className='bannertext text-secondary my-5'>Find a variety of properties that suti you very easily  <br />Forget all difficultes in finding a residence for you</p>
                        <div className='searchbox my-3'>
                            <div className='row'>
                                <div className='col-1'>
                                    <h1 className='icona text-dark'>I</h1>
                                </div>
                                <div className='col-sm-9 col-lg-8'>
                                    <form>
                                        <input className='searchinput' type="text" value={search} onChange={(e) => { setSearch(e.target.value.toLowerCase()) }} />
                                    </form>
                                </div>
                                <div className='col-2'>
                                    <button className='searchbut btn btn-primary' >Search</button>
                                </div>
                            </div>
                        </div>
                        <div className='stats'>
                            <div className='row'>
                                <div className='col-4'>
                                    <div className='premium'>
                                        <div className='premiumcount' style={{marginLeft:"10px"}}>
                                        <CountUp start={1000} end={9000} duration={10} />
                                        <span className='text-warning'> +</span>
                                        </div>
                                        <p className='text-secondary'>Premium Product</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='customers'>
                                        <div className='customerscount' style={{marginLeft:"10px"}}>
                                        <CountUp start={1000} end={2000} duration={100} />
                                        <span className='text-warning'> +</span>
                                        </div>
                                        <p className='text-secondary'>Happy Customers</p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                <div className='award'>
                                    <div className='awardcount' style={{marginLeft:"10px"}}>
                                    <CountUp  end={28}  duration={100} align="center"/>
                                        <span className='text-warning'> +</span>
                                    </div>
                                        <p className='text-secondary'>Award Winnings</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' col-lg-7 '>
                        <img src={BannerImg} className='bannerImg ' alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;
