export const sliderSettings = {
   slidesPerView:1,
   spaceBetween:50,
   breakpoints:{
    320:{
        slidesPerView:1
    },
    375:{
        slidesPerView:1
    },
    425:{
        slidesPerView:1
    },
    768:{
        slidesPerView:3
    },
    1024:{
        slidesPerView:4
    }
   }
}