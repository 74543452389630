import React from 'react';
import "./Css/Navbar.css";
const Navbar = () => {
  return (
    <div >
      <header className='mb-5' >
        <nav className="navbar navbar-expand-lg bg-dark  fixed-top ">
          <div className="container">
            <h1 className="navbar-brand text-light" >Realest</h1>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon " style={{ backgroundColor: "white" }}></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarSupportedContent" align="right">
              <div className=" me-auto">
              </div>
              <div className="d-flex" >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                  <li className="nav-item ">
                  <a href='#residencies' className="nav-link active text-light">Residencies</a>                  
                  </li>
                  <li className="nav-item">
                  <a href='#ourvalue' className="nav-link active text-light">Our Value</a>    
                  </li>
                  <li className="nav-item">
                  <a href="#contactus" className="nav-link active text-light">Contact Us</a>   
                   
                  </li>
                  <li className="nav-item">
                  <a href="#getstart" className="nav-link active text-light">Get Start</a>   
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Navbar;
