import React from 'react';
import './Css/Companies.css'
import Cmp1 from "../images/prologis.png";
import Cmp2 from '../images/tower.png';
import Cmp3 from '../images/equinix.png';
import Cmp4 from '../images/realty.png';
const Companies = () => {
    return (
        <div className='container'>
            <div className='row py-5'>
                <div className='col-3'>
                    <img src={Cmp1}  className="compimg1 w-100" alt="" />
                </div>
                <div className='col-3'>
                    <img src={Cmp2} className="compimg2 w-100 " alt="" />
                </div>
                <div className='col-3'>
                    <img src={Cmp3} className="compimg3 w-100 " alt="" />
                </div>
                <div className='col-3'>
                    <img src={Cmp4} className="compimg4 w-100 " alt="" />
                </div>
            </div>
        </div>
    )
}

export default Companies;
