import React from 'react';
import ValueImg from "../images/value.png"
import "./Css/Value.css";
import data from './Datas/accodion';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css"
import { MdOutlineArrowDropDown } from "react-icons/md";

const Value = () => {
  return (
    <div className='container my-5' id ="ourvalue">
      <div className='row'>
        <div className='col-lg-6 d-sm-none  d-lg-block '>
          <img src={ValueImg} className='valueimg' alt="" />
        </div>
        <div className='col-lg-6 col-sm-12 col-md-12'>
          <h2 className='text-warning'>Our Value</h2>
          <h1 className='text-primary'>Value We Give to You</h1>
          <p className='text-scondary'>We always ready to help by providing the best services for you <br />We belive a good basic to live can make you life better</p>
          <Accordion className='accordion border-0' allowMultipleExpanded={false}
            preExpanded={[0]}>
            {data.map((value, i) => {
              return (
                <AccordionItem className='accordionItem' key={i} uuid={i}>
                  <AccordionItemButton className='accodionButton'>
                    <div className='row'>
                      <div className=' col-2'>
                        <p className='icon'>
                          {value.icon}
                        </p></div>
                      <div className='col-8 text-primary text-center'>
                        <h3 style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                          {value.heading}
                        </h3></div>
                      <div className=' col-2'>
                        <p className='icon'>
                          <MdOutlineArrowDropDown />
                        </p>
                      </div>
                    </div>
                  </AccordionItemButton>
                  <AccordionItemPanel>
                    <p className='text-secondary'>{value.detail}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Value;
