import React from 'react';
import './App.css';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Banner from "./Utils/Banner";
import Companies from "./Utils/Companies"
import Residencies from './Utils/Residencies';
import Value from './Utils/Value';
import Contact from "./Utils/Contact";
import GetStart from "./Utils/GetStart";
const App = () => {
  return (
    <div width="400px" sx={{ width: { xl: '1488px' } }} m="auto">
      <Navbar />
      <Banner />
      <Companies/>
      <Residencies />
      <Value/>
      <Contact/>
      <GetStart/>
      <Footer />
    </div>

  )
}

export default App;
