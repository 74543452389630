import React from 'react';
import "./Css/GetStart.css";
const GetStart = () => {
  return (
    <div className='container my-5' id="getstart">
      <div className='bg bg-primary text-center p-5'>
        <h3 className='gettext text-light py-2'>Get Start with realest</h3>
        <p className='text-secondary py-1'>Subscribe and find super attractive price quotes form us.<br/>Find your residence soon.</p>
        <button className='getstartbtn btn text-light'>Get Start</button>
      </div>
    </div>
  )
}

export default GetStart;
