import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import img from "../images/r1.png"
import "./Css/Residencies.css";
import data from "./Datas/slider.json";
import { sliderSettings } from './Common';
const Residencies = () => {

  return (
    <div className='container' id="residencies">
      <h3 className='text-warning'>Best Choices</h3>
      <h2>Popular Residencies</h2>
      <Swiper {...sliderSettings}>
        <SliderButton/>
       
        {
          data.map((value, i) => {
            return (
              <SwiperSlide key={i}>
                <div className='rescard card '>
                  <img src={img} alt="imges" />
                  <p className='text-secondary'><span className='text-warning'>$</span> {value.price}</p>
                  <h4 className='text-primary'>{value.name}</h4>
                  <p>{value.detail}</p>
                </div>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  )
}

const SliderButton=()=>{
  const swiper=useSwiper();
  return(
    <>
    <div className='m-3' align="right">
    <button onClick={()=>swiper.slidePrev()} className='swipbut mx-1 p-1 px-2 bg-light'>&lt;</button>
    <button onClick={()=>swiper.slideNext()} className='swipbut mx-1 p-1 px-2 bg-light'>&gt;</button>
    </div>
    </>
  )
}

export default Residencies;



