import React from 'react';
import "./Css/Contact.css";
import ContactImg from "../images/contact.jpg";
import { MdCall } from "react-icons/md"
import { MdVideoCall } from "react-icons/md"
import { BsFillChatDotsFill } from "react-icons/bs";
import { HiChatBubbleBottomCenter } from "react-icons/hi2";
const Contact = () => {
  return (
    <div className='container' id="contactus">
      <div className='row'>
        <div className='col-lg-6  col-md-12 col-sm-12'>
          <h3 className='text-warning'>Our Contact Us</h3>
          <h2 className='text-primary'>Easy to contact us</h2>
          <p className='text-secondary'>We always ready to help by providing the best service for you wer belive a good <br />place to liv3e can make your life better</p>
          <div className='row'>
            <div className='col-6 '>
              <div className='card border-0 card1 my-4 p-2'>
                <div className='row'>
                  <div className='col-2'>
                    <MdCall className='conticon'/>
                  </div>
                  <div className='col-8'>
                    <p className='text-primary'>Call<br /> <span className='text-secondary'>02569795236</span> </p>
                  </div>
                </div>
                <button className='btn btn-outline-primary m-2'>Call now</button>
              </div>
              <div className='card border-0 card1 my-4 p-2'>
                <div className='row'>
                  <div className='col-2'>
                    <MdVideoCall className='conticon'/>
                  </div>
                  <div className='col-8'>
                    <p className='text-primary'>Video Call<br /> <span className='text-secondary'>02569795236</span> </p>
                  </div>
                </div>
                <button className='btn btn-outline-primary m-2 p-2'>Video Call now</button>
              </div>
            </div>
            <div className='col-6 my-4'>
              <div className='card border-0 card1 p-2'>
                <div className='row'>
                  <div className='col-2'>
                    <BsFillChatDotsFill className='conticon'/>
                  </div>
                  <div className='col-8'>
                    <p className='text-primary'>Chat<br /> <span className='text-secondary'>02569795236</span> </p>
                  </div>
                </div>
                <button className='btn btn-outline-primary m-2'>Chat now</button>
              </div>
              <div className='card border-0 card1 my-4 p-2'>
                <div className='row'>
                  <div className='col-2'>
                    <HiChatBubbleBottomCenter className='conticon'/>
                  </div>
                  <div className='col-8'>
                    <p className='text-primary'>Message<br /> <span className='text-secondary'>02569795236</span> </p>
                  </div>
                </div>
                <button className='btn btn-outline-primary m-2'>Message now</button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6  d-sm-none  d-lg-block'>
          <img src={ContactImg} className='contactimg' alt="" />
        </div>
      </div>
    </div>
  )
}

export default Contact;
