import React from 'react'

const Footer = () => {
  return (
    <div className='container my-5'>
      <div className='row'>
        <div className='col-lg-7 col-md-6'>
          <h3 className='text-primary'>Realest</h3>
          <p className='text-secondary'>Oru vision is to make all people <br/>the best place to live for them.</p>
        </div>
        <div className='col-lg-5 col-md-6'>
          <h3 className='text-primary'>Information</h3>
          <p className='text-secondary'>145 Kanyakumari</p>
          <div className='row'>
            <div className='col-3'>
              <p>Property</p>
            </div>
            <div className='col-3'>
              <p>Service</p>
            </div>
            <div className='col-3'>
              <p>Product</p>
            </div>
            <div className='col-3'>
              <p>About Us</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
